<template>
    <Spinner v-if="!loaded.tasks && !loaded.currentTask && !loaded.profileInfo" />
    <div v-else>
        <div v-if="currentTask.active" class="border border-success border-3 rounded-3 shadow-lg ms-4 me-4 d-flex align-items-center mb-5">
            <h3 class="mt-2 mb-2 ms-2 flex-grow-1">{{currentTask.task.name}}</h3>
            <h4 class="mt-2 mb-2 me-3">{{formatSeconds(currentTask.length)}}</h4>
            <button @click="stopCurrentTask" class="btn btn-danger me-3">Stop</button>
        </div>
   
        <div v-for="(task, task_i) in tasks" v-bind:key="task" class="border border-light border-2 rounded-3 shadow-sm ms-5 me-5 d-flex align-items-center mb-3">
            <h3 class="mt-2 mb-2 ms-2 flex-grow-1" data-bs-toggle="modal" :data-bs-target="'#task'+task_i.toString()">{{task.task.name}}</h3>
            <h4 class="mt-2 mb-2 me-3">
                {{formatSeconds(task.total)}}
                /
                {{formatSeconds(((task.task.length*profileInfo.total)-task.total)/Math.ceil(profileInfo.left/(24*60*60)))}}
            </h4>
            <button v-if="!currentTask.active" @click="startTask(task.task.id)" class="btn btn-primary me-3">Start</button>
            <Modal :id="'task'+task_i.toString()" :title="task.task.name">
                <hr>
                <div class="float-end">
                    <small>Delete this task: </small>
                    <button class="btn btn-danger btn-sm" data-bs-toggle="modal" :data-bs-target="'#task'+task_i.toString()" @click="deleteTask(task.task.id)">Delete</button>
                </div>
            </Modal>
        </div>
        <Modal id="newTask" title="Add new task">
            <form @submit.prevent="addNewTask">
                <div class="input-group mb-3">
                    <label class="input-group-text" for="name">Task name</label>
                    <input type="text" class="form-control" id="name" v-model="newTask.name">
                </div>
                <div class="input-group mb-3 align-items-center">
                    <div class="d-flex">
                        <label for="timeAllocated" class="form-label flex-grow-1 ">Time allocated</label>
                        <input type="number" class="form-control ms-2" min="0.01" :max="(1-profileInfo.ocupied)*100" step="0.01" v-model="newTask.timeAllocated">
                        <label for="timeAllocated" class="form-label">% | {{formatSeconds(profileInfo.total*(newTask.timeAllocated/100))}}</label>
                    </div>
                    <input type="range" class="form-range" min="0.01" :max="(1-profileInfo.ocupied)*100" step="0.01" id="timeAllocated" v-model="newTask.timeAllocated">
                </div>
                <input type="submit" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#newTask">
            </form>
        </Modal>
        <ActionButton data-bs-toggle="modal" data-bs-target="#newTask" />
    </div>
</template>

<style scoped>
</style>

<script>
 import {authFetch} from '@/lib/api.js'
 import Spinner from '@/components/Spinner.vue'
 import Modal from '@/components/Modal.vue'
 import ActionButton from '@/components/ActionButton.vue'
 import {code} from '@/lib/auth.js'

 export default {
     name: 'ProfileDashboard',
     components: {
         Spinner,
         Modal,
         ActionButton,
     },
     data(){
         return {
             loaded: {tasks: false, currentTask: false, profileInfo: false},
             tasks: {},
             currentTask: {},
             profile: this.$route.params.profile,
             newTask: {name: '', timeAllocated: 0.1},
             profileInfo: {}
         }
     },
     methods: {
         formatSeconds(secs){
             let d = secs;
             let h = Math.floor(d / 3600);
             let m = Math.floor(d % 3600 / 60);
             let s = Math.floor(d % 3600 % 60);
             let out = ""
             
             if (h>0){
                 if (h < 10){
                     out += '0'
                 }
                 out += h.toString()+":"
             }
             if (m < 10){
                 out += '0'
             }
             out += m.toString() + ":"
             if (s < 10){
                 out += '0'
             }
             out += s.toString()
             return out
         },
         refreshTasks(){
             authFetch('/timemgmt/tasks', 'GET', {profile: this.profile})
                 .then((response) => {
                     console.log(response)
                     this.tasks = response.data
                     for (let i=0; i<this.tasks.length; i++) {
                         this.tasks[i].task.name = code.decryptMessage(this.tasks[i].task.name) 
                     }
                     console.log(this.tasks, this.tasks.length)
                     this.loaded.tasks = true

                 })
                 .catch((error) => {
                     console.log(error.response)
                 })
             if (this.activeInterval) {
                 clearInterval(this.activeInterval)
             }
             authFetch('/timemgmt/current', 'GET', {profile: this.profile})
                 .then((response) => {
                     console.log(response)
                     this.currentTask = response.data
                     this.currentTask.task.name = code.decryptMessage(this.currentTask.task.name)
                     if (this.currentTask.active == true){
                         this.activeInterval = setInterval(() => {this.currentTask.length++}, 1000)
                     }
                     this.loaded.currentTask = true

                 })
                 .catch((error) => {
                     console.log(error.response)
                 })
             authFetch('/timemgmt/profile', 'GET', {profile: this.profile})
                 .then((response) => {
                     console.log(response)
                     this.profileInfo = response.data
                     this.loaded.profileInfo = true
                 })
                 .catch((error) => {
                     console.log(error.response)
                 })
         },
         stopCurrentTask(e){
             authFetch('/timemgmt/current', 'POST', {action: false, profile: this.profile})
                 .then((response) => {this.refreshTasks()})
                 .catch((error) => {console.log(error.response)})
             
         },
         startTask(id){
             authFetch('/timemgmt/current', 'POST', {action: true, profile: this.profile, id: id})
                 .then((response) => {this.refreshTasks()})
                 .catch((error) => {console.log(error.response)})
         },
         addNewTask(e){
             authFetch('/timemgmt/task', 'POST', {name: code.encryptMessage(this.newTask.name), length: ((this.newTask.timeAllocated)/100).toFixed(4), profile: this.profile})
                 .then((response) => {
                     console.log(response)
                     this.refreshTasks()
                 })
                 .catch((error) => {
                     console.log(error.response)
                 })
         },
         deleteTask(id){
             authFetch('/timemgmt/task', 'DELETE', {id: id})
                 .then((response) => {
                     console.log(response)
                     this.refreshTasks()
                 })
                 .catch((error) => {
                     console.log(error.response)
                 })
         }
     },
     beforeMount(){
        this.refreshTasks() 
     },
     unmounted(){
         if (this.activeInterval) {
             clearInterval(this.activeInterval)
         }      
     },
 }
</script>
